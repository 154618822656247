<template>
  <div class="card card-custom p-0">
    <div class="card-header">
      <div class="card-title">Passdaten-Konfiguration</div>
      <div class="card-toolbar d-flex flex-nowrap text-nowrap">
        <b-button size="sm" class="mr-1" @click="selectAllRows">{{ $t('COMMON.ALLEAUSWAEHLEN') }}</b-button>
        <b-button size="sm" @click="clearSelected">{{ $t('COMMON.AUSWAHLAUFHEBEN') }}</b-button>
      </div>
    </div>
    <div v-if="konfigurationen" class="card-body p-0">
      <b-table
        table-class="reisetermin-table mb-0"
        :key="'b-table-reisetermin-missing-data'"
        :id="'b-table-reisetermin-missing-data'"
        :ref="'b-table-reisetermin-missing-data'"
        :items="konfigurationen"
        :fields="fields"
        :no-local-sorting="isAddingKonfiguration"
        :sort-desc="true"
        :empty-filtered-text="$t('COMMON.EMPTYFILTEREDTEXT', { name: 'Reisetermine' })"
        :empty-text="$t('COMMON.EMPTYTEXT', { name: 'Reisetermine' })"
        :busy="isBusy"
        :tbody-tr-class="rowClass"
        sort-by="gueltigAb"
        sort-direction="desc"
        selectable
        responsive
        small
        bordered
        show-empty
        thead-tr-class="text-center px-0"
        @sort-changed="$emit('sortChange', $event)"
        @row-selected="onRowSelected"
      >
        <template #cell(gueltigAb)="{ item }">
          <b-form-input
            :class="item.newgueltigAb ? 'border-warning border' : ''"
            :value="formatDate(item.newgueltigAb || item.gueltigAb)"
            type="date"
            @input="onDateInput($event, item, 'gueltigAb')"
          >
          </b-form-input>
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span class="m-5 font-size-h1"> &check; </span>
          </template>
          <template v-else> <b-form-checkbox disabled size="lg"> </b-form-checkbox></template>
        </template>
        <template #cell()="{ item, field }">
          <b-form-checkbox
            @change="onInputCheckBox($event, item, field.key)"
            button-variant="secondary"
            v-model="item[field.key]"
            size="lg"
          >
          </b-form-checkbox>
        </template>

        <template #bottom-row="{ columns, fields }">
          <td :colspan="columns">
            <b-button
              v-if="!isAddingKonfiguration"
              size="sm"
              class="w-90 m-2"
              variant="secondary"
              @click="onClickAddKonfiguration($event)"
            >
              <b-icon-plus></b-icon-plus>{{ $t('COMMON.BUTTON.ADD') }}</b-button
            >
            <template v-else>
              <b-button
                size="sm"
                class="w-90 m-2"
                variant="success"
                @click="onClickSaveNewKonfiguration($event)"
              >
                Speichern</b-button
              >
              <b-button size="sm" class="" variant="danger" @click="onClickCancelNewKonfiguration($event)">
                {{ $t('COMMON.ABBRECHEN') }}</b-button
              >
            </template>
          </td>
        </template>

        <template #cell(actions)="{ item }">
          <ActionButton
            :disabled="isAddingKonfiguration"
            variant="success"
            iconClass="b-icon-check"
            title="Speichern"
            @click="onClickSaveKonfiguration($event, item)"
          />
          <ActionButton
            v-if="item.newgueltigAb"
            :disabled="isAddingKonfiguration"
            variant="secondarywq"
            iconClass="b-icon-x"
            title="Verwerfen"
            @click="onClickCancelEdit($event, item)"
          />
          <ActionButton
            :disabled="isAddingKonfiguration"
            title="Löschen"
            iconClass="b-icon-trash"
            variant="danger"
            @click="onClickDeleteKonfiguration($event, item)"
          />
        </template>
      </b-table>
    </div>
    <div v-else class="card-body">
      <b-skeleton-table :rows="12" :columns="7"></b-skeleton-table>
    </div>
  </div>
</template>

<script>
import {
  ADD_KONFIGURATION,
  DELETE_KONFIGURATION,
  UPDATE_KONFIGURATION,
} from '@/core/produkte/reisen/stores/reisen.module';
import ActionButton from '@/components/common/action-button.vue';

export default {
  name: 'ReiseterminMissingDataTable',
  components: {
    ActionButton,
  },
  props: {
    reisetermine: {
      type: Array,
    },
    konfigurationen: {
      type: Array,
    },
    reisekuerzel: {
      type: String,
    },
    count: { type: Number },
    isInitiallyLoading: { type: Boolean },
    isBusy: {
      type: Boolean,
    },
  },
  data() {
    return {
      selected: [],
      isAddingKonfiguration: false,
      selectedKonfiguration: null,
      fields: [
        {
          label: 'Auswählen',
          key: 'selected',
          class: 'fixed-height-td position-sticky cw-75 text-nowrap text-center',
        },
        {
          label: this.$t('COMMON.GUELTIGAB'),
          key: 'gueltigAb',
          sortable: true,
          class: 'position-sticky cw-150 b-table-sort-icon-left',
        },
        {
          key: 'geburtsdatumRequired',
          label: this.$t('COMMON.GEBURTSDATUM'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'passNummerRequired',
          label: this.$t('KONFIGURATION.passNummerRequired'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'passartRequired',
          label: this.$t('KONFIGURATION.passartRequired'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'nationalitaetRequired',
          label: this.$t('KONFIGURATION.nationalitaetRequired'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'geschlechtRequired',
          label: this.$t('KONFIGURATION.geschlechtRequired'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'passAusstellungsdatumRequired',
          label: this.$t('KONFIGURATION.PASSAUSSTELLUNGSDATUM'),
          tdClass: 'fixed-height-td cw-200',
        },
        {
          key: 'passGueltigkeitsdatumRequired',
          label: this.$t('KONFIGURATION.PASSGUELTIGKEITSDATUM'),
          tdClass: 'fixed-height-td cw-200',
        },
        {
          key: 'passLandRequired',
          tdClass: 'fixed-height-td cw-150',
          label: this.$t('KONFIGURATION.PASSLAND'),
        },
        {
          key: 'passOrtRequired',
          tdClass: 'fixed-height-td cw-150',
          label: this.$t('KONFIGURATION.PASSORT'),
        },
        {
          key: 'geburtsortRequired',
          label: this.$t('KONFIGURATION.geburtsortRequired'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'geburtslandRequired',
          label: this.$t('KONFIGURATION.geburtslandRequired'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'passkopieBwRequired',
          label: this.$t('KONFIGURATION.PASSKOPIE'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'passkopieColorRequired',
          label: this.$t('KONFIGURATION.PASSFARBKOPIE'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'passfotoRequired',
          label: this.$t('KONFIGURATION.PASSFOTO'),
          tdClass: 'fixed-height-td cw-150',
        },
        {
          key: 'actions',
          label: this.$t('REISEN.COMMON.ACTIONS'),
          tdClass: 'fixed-height-td cw-150 text-center align-middle',
        },
      ],
    };
  },
  watch: {
    reisetermine() {
      this.isAddingKonfiguration = false;
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type != 'row') {
        return '';
      }
      return item.new ? 'bg-success-o-50' : item.edited ? 'bg-warning-o-50' : '';
    },
    formatDate(value) {
      return new Date(value).toISOString().split('T')[0];
    },
    onDateInput($event, item, field) {
      if ($event) {
        const tmpItem = { ...item, ['new' + field]: new Date($event).toISOString(), edited: true };
        const newKonfigurationIndex = this.konfigurationen.findIndex(
          konfiguration => tmpItem.id == konfiguration.id
        );
        this.$set(this.konfigurationen, newKonfigurationIndex, tmpItem);
      }
    },
    onClickCancelEdit($event, item, field) {
      item.newgueltigAb = null;
      item.edited = false;
    },
    onInputCheckBox($event, item, key) {
      if (this.selected.length > 0) {
        this.selected.map(konfiguration => {
          konfiguration[key] = $event;
          konfiguration.edited = true;
        });
      }
    },
    onClickCancelNewKonfiguration($event) {
      this.isAddingKonfiguration = false;
      this.konfigurationen.pop();
    },
    onClickSaveNewKonfiguration($event) {
      this.isAddingKonfiguration = false;
      const newKonfigurationIndex = this.konfigurationen.findIndex(konfiguration => konfiguration.new);
      const [newKonfiguration] = this.konfigurationen.splice(newKonfigurationIndex, 1);
      newKonfiguration.gueltigAb = newKonfiguration.newgueltigAb
        ? newKonfiguration.newgueltigAb
        : newKonfiguration.gueltigAb;
      delete newKonfiguration.newgueltigAb;
      delete newKonfiguration.new;
      delete newKonfiguration.edited;
      this.$store
        .dispatch(ADD_KONFIGURATION, {
          konfiguration: newKonfiguration,
          reisekuerzel: this.reisekuerzel,
        })
        .then(() => {
          this.toast(this.$t('REISETERMINE.TOAST.UPDATED'), 'success');
        });
    },
    getMostRecentConfig() {
      return [...this.konfigurationen].sort((a, b) => a.gueltigAb < b.gueltigAb)[0];
    },
    onClickAddKonfiguration($event) {
      this.isAddingKonfiguration = true;
      const tempDate = new Date();
      const untilDate = new Date(tempDate.getFullYear() + 1, tempDate.getMonth(), tempDate.getDate());
      const mostRecentConfig = this.getMostRecentConfig();
      this.konfigurationen.push({
        geburtsdatumRequired: mostRecentConfig?.geburtsdatumRequired || false,
        passNummerRequired: mostRecentConfig?.passNummerRequired || false,
        passartRequired: mostRecentConfig?.passartRequired || false,
        nationalitaetRequired: mostRecentConfig?.nationalitaetRequired || false,
        geschlechtRequired: mostRecentConfig?.geschlechtRequired || false,
        passAusstellungsdatumRequired: mostRecentConfig?.passAusstellungsdatumRequired || false,
        passGueltigkeitsdatumRequired: mostRecentConfig?.passGueltigkeitsdatumRequired || false,
        passLandRequired: mostRecentConfig?.passLandRequired || false,
        passOrtRequired: mostRecentConfig?.passOrtRequired || false,
        geburtsortRequired: mostRecentConfig?.geburtsortRequired || false,
        geburtslandRequired: mostRecentConfig?.geburtslandRequired || false,
        passkopieBwRequired: mostRecentConfig?.passkopieBwRequired || false,
        passkopieColorRequired: mostRecentConfig?.passkopieColorRequired || false,
        passfotoRequired: mostRecentConfig?.passfotoRequired || false,
        new: true,
        gueltigAb: tempDate.toISOString(),
      });
    },
    async onClickDeleteKonfiguration($event, konfiguration) {
      const confirmed = await this.$bvModal.msgBoxConfirm(this.$t('KONFIGURATION.WIRKLICHLOESCHEN'));
      if (!confirmed) {
        this.toast(this.$t('COMMON.AKTIONABGEBROCHEN'), 'warning');
        return;
      }
      this.$store
        .dispatch(DELETE_KONFIGURATION, {
          id: konfiguration.id,
          reisekuerzel: this.reisekuerzel,
        })
        .then(() => {
          this.toast(this.$t('REISETERMINE.TOAST.UPDATED'), 'success');
        });
    },
    onClickSaveKonfiguration($event, konfiguration) {
      const updatedKonfiguration = {
        ...konfiguration,
        gueltigAb: konfiguration.newgueltigAb || konfiguration.gueltigAb,
      };
      delete updatedKonfiguration.passdaten;
      delete updatedKonfiguration.edited;
      delete updatedKonfiguration.newgueltigAb;
      delete updatedKonfiguration.dokumente;
      delete updatedKonfiguration.createdDateUtc;
      delete updatedKonfiguration.updatedDateUtc;
      delete updatedKonfiguration.lastModifiedUserId;
      this.$store
        .dispatch(UPDATE_KONFIGURATION, {
          id: konfiguration.id,
          konfiguration: updatedKonfiguration,
          reisekuerzel: this.reisekuerzel,
        })
        .then(() => {
          this.toast(this.$t('REISETERMINE.TOAST.UPDATED'), 'success');
        });
    },
    selectAllRows() {
      this.$refs['b-table-reisetermin-missing-data'].selectAllRows();
    },
    clearSelected() {
      this.$refs['b-table-reisetermin-missing-data'].clearSelected();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    onPageChange($event) {
      this.$emit('pageChange', $event);
      this.$refs['b-table-reisetermin-missing-data'].$el.scrollTop = 0;
    },
    onToggleDetailsClick($event, row) {
      row.toggleDetails();
    },
  },
};
</script>
<style lang="scss">
.cw-75 {
  width: 75px;
}
.cw-100 {
  width: 100px;
}
.cw-150 {
  width: 150px;
}
.cw-200 {
  width: 200px;
}
</style>
<style lang="scss" scoped>
// Needs fixed height to make children relative height possible
:deep(.custom-checkbox.b-custom-control-lg .custom-control-label::after, .input-group-lg
    .custom-checkbox
    .custom-control-label::after) {
  top: 0.25rem;
  left: -1.875rem;
  width: 2.3rem;
  height: 2.3rem;
  background-size: 50% 50%;
}

:deep(.custom-checkbox.b-custom-control-lg .custom-control-label::before, .input-group-lg
    .custom-checkbox
    .custom-control-label::before) {
  top: 0.3125rem;
  left: -1.875rem;
  width: 2.2rem;
  height: 2.3rem;
  border-radius: 0.3rem;
}

:deep(.custom-checkbox.b-custom-control-lg, .input-group-lg .custom-checkbox) {
  height: 40px;
  text-align: center;
}

:deep(input[type='checkbox']) {
  height: 100%;
  width: 100%;
}

:deep(.table-sm td) {
  padding: 0;
}
</style>
