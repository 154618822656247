<template>
  <div>
    <ReiseterminPassdatenKonfigurationTable
      v-if="reise"
      :count="reise.reisetermine ? reise.reisetermine.length : 0"
      :reisetermine="reise.reisetermine"
      :reisekuerzel="reise.reisekuerzel"
      :konfigurationen="reise.konfigurationen"
      :isBusy="isBusy"
      :isInitiallyLoading="isInitiallyLoading"
    />
    <div v-else class="card card-custom p-0">
      <div class="card-header">
        <div class="card-title">
          <b-skeleton height="24px" width="600px"></b-skeleton>
        </div>
      </div>
      <div class="card-body p-0">
        <b-skeleton-table :rows="6" :columns="7"></b-skeleton-table>
      </div>
    </div>
  </div>
</template>

<script>
import ReiseterminPassdatenKonfigurationTable from '@/components/produkte/reisetermine/reisetermin-passdaten-konfiguration-table.vue';
export default {
  name: 'ReiseViewComponent',
  components: {
    ReiseterminPassdatenKonfigurationTable,
  },
  props: {
    dataTab: String,
    reise: { type: Object },
    isInitiallyLoading: { type: Boolean },
    isBusy: { type: Boolean },
  },
  data() {
    return {};
  },
  computed: {
    activeTabFromRouter() {
      return this.$route.query.dataTab || 'seeddata';
    },
  },
  methods: {},
};
</script>
